<template>
  <div class="console_r">
    <div class="console_r_card">
      <div class="title">
        <b>{{currMenuItem.name}}</b>
        <!-- <span style="cursor: pointer" @click="instrDialogVisible = true">案例说明</span> -->
      </div>
      <div class="con">
        <ul>
          <li v-for="(item, index) in currMenuItem.fun" :key="index">{{item}}<i></i></li>
          <div style="clear: both;"></div>
        </ul>
        <div class="des">
          <p v-for="(item, index) in currMenuItem.des" :key="index">{{item}}</p>
        </div>
        <sh-input v-if="!!currMenuItem.showIdInput" class="ipt" v-model="postParams.chicken_custom_id" placeholder="请输入家禽的ID"></sh-input>
        <el-upload
          class="upload-warp"
          ref="upload"
          :action="`${baseUrl}api/upload/ali-oss`"
          :headers="uploadHeaders"
          :multiple="false"
          :limit="1"
          :file-list="fileList"
          :auto-upload="false"
          accept="image/png,image/jpeg,image/jpg"
          :on-exceed="handleExceed"
          :on-change="handleChange"
          :on-success="handleSuccess"
          :on-progress="handleProgress"
          :on-error="handleError"
          :show-file-list="false">
          <div class="upload" slot="trigger">
            <span v-if="fileList.length<=0" class="c9">请选择上传文件</span>
            <span v-if="fileList.length>0">{{fileList[0].name}}</span>
            <div class="btn">
              <sh-button size="small"></sh-button>
            </div>
          </div>
        </el-upload>
        <div>
          <div class="ipt_notes" style="text-align: left; margin-top: 10px">
            <sh-button class="button-a" @click="submitUpload">上传</sh-button><span v-if="isShowProgress">{{`上传进度：${uploadProgress}%`}}</span> {{uploadTip}}
          </div>
          <div slot="tip" class="el-upload__tip">只能上传jpg/jpeg/png文件，且不超过15M</div>
        </div>
      </div>
    </div>
    <div class="console_r_card">
      <div class="title">
        <b>历史记录<i @click="refresh" class="el-icon-refresh" title="刷新"></i></b>
        <span>当前记录：{{count}}</span>
      </div>
      <div class="con">
        <div class="con_top" style="padding: 30px 0;">
          <el-select v-model="queryParams.chicken_custom_id" style="width: 200px">
            <el-option value="" label="全部">全部</el-option>
            <el-option v-for="(item, index) in chickenIdList" :key="index" :label="item.custom_id" :value="item.custom_id"></el-option>
          </el-select>
          <!-- <el-input style="width: 240px" v-model="queryParams.keyword" placeholder="请输入文件名" clearable></el-input> -->
          <el-date-picker
            v-model="dateTimeFilter"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :editable="false"
            size="large"
            @change="dateChange"
            align="right">
          </el-date-picker>
          <sh-button @click="search" round>查询</sh-button>
          <sh-button @click="handleExport" round>导出</sh-button>
        </div>
        <el-table :data="list" style="width: 100%" border>
          <el-table-column label="缩略图" width="120">
            <template slot-scope="scope">
              <div class="itme-img" :style="`background-image: url(${scope.row.detect_image})`"></div>
            </template>
          </el-table-column>
          <el-table-column prop="comb_perimeter" label="鸡冠周长(cm)" width="110"></el-table-column>
          <el-table-column prop="comb_area" label="鸡冠面积(cm²)" width="120"></el-table-column>
          <el-table-column prop="comb_color_level" label="鸡冠颜色等级" width="110"></el-table-column>
          <el-table-column prop="comb_rgb_mean" label="鸡冠平均RGB" width="150"></el-table-column>
          <el-table-column prop="comb_rgb_std" label="鸡冠标准差RGB" width="150"></el-table-column>
          <el-table-column prop="comb_height" label="鸡冠高度(cm)" width="110"></el-table-column>
          <el-table-column prop="wattle_perimeter" label="鸡垂周长(cm)" width="110"></el-table-column>
          <el-table-column prop="wattle_area" label="鸡垂面积(cm²)" width="120"></el-table-column>
          <el-table-column prop="wattle_color_level" label="鸡垂颜色等级" width="110"></el-table-column>
          <el-table-column prop="wattle_rgb_mean" label="鸡垂平均RGB" width="150"></el-table-column>
          <el-table-column prop="wattle_rgb_std" label="鸡垂标准差RGB" width="150"></el-table-column>
          <el-table-column prop="eye_width" label="鸡眼宽度(cm)" width="110"></el-table-column>
          <el-table-column prop="eye_height" label="鸡眼高度(cm)" width="110"></el-table-column>
          <el-table-column prop="beak_area" label="鸡喙面积(cm²)" width="120"></el-table-column>
          <el-table-column prop="statusTxt" label="状态"></el-table-column>
          <el-table-column prop="date" label="日期" width="166"></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="viewDetail(scope.row)" round>详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center">
        <el-pagination
        style="padding: 20px 0 40px"
        background
        layout="prev, pager, next"
        @current-change="currPageChange"
        :page-size="queryParams.limit"
        :current-page.sync="currPage"
        :total="count">
        </el-pagination>
      </div>
    </div>
    <el-dialog 
    class="dialog"
    title="案例说明" 
    :width="`${currMenuItem.instr.length * 350}px`"
    :visible.sync="instrDialogVisible">
      <div class="detail-item">
        <ul class="instr_con">
          <li v-for="(item, index) in currMenuItem.instr" :key="index" :style="`width: ${100/currMenuItem.instr.length}%`">
            <img alt="" :src="item.img" />
            <p v-html="item.des"></p>
          </li>
        </ul>
        <div style="clear: both"></div>
      </div>
    </el-dialog>
    <el-dialog 
    class="dialog"
    title="详情" 
    width="800px"
    :visible.sync="detailDialogVisible">
      <div class="detail-item">
        <div class="item-con">
          <!-- <div class="img" style="width: 740px; height:550px; margin-bottom: 20px; float: none;" :style="`background-image: url(${(currType === 'breed' || currType === 'egg') ? currViewDetailData.source_image : currViewDetailData.detect_image})`"></div> -->
          <img alt="" :src="(currType === 'breed' || currType === 'egg') ? currViewDetailData.source_image : currViewDetailData.detect_image" />
        </div>
      </div>
      <div class="detail-item" v-if="!!currMenuItem.showIdInput">
        <el-form ref="editPostParams" :model="editPostParams" label-width="140px">
          <el-form-item label="家禽ID：" v-if="!!currMenuItem.showIdInput">
            <sh-input v-model="editPostParams.chicken_custom_id" placeholder="请输入家禽ID"></sh-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right;">
          <sh-button 
          @click="updateChickenById(currViewDetailData.id)" 
          :disabled="editPostParams.chicken_custom_id === currViewDetailData.chicken_custom_id"
          >修 改</sh-button>
        </div>
      </div>
      <div class="detail-item">
        <div v-for="(item, index) in currMenuItem.detailList" :key="index"><span>{{item.title}}：</span>{{currViewDetailData[item.key]}} {{item.unit}}</div>
        <div><span>处理状态：</span>{{currViewDetailData.statusTxt}}</div>
        <div><span>处理时间：</span>{{currViewDetailData.date}}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { Message } from 'element-ui';
import { submitChickenFun, getChickenListFun, getChickenCountFun, getChickenIdListFun, updateChickenFun } from '@/api/user'
import { getToken, getAccountId } from '@/libs/util'
import config from '@/config'
import ShInput from '@/components/uikit/input'
import ShButton from '@/components/uikit/button'

export default {
  name: 'PoultryIndex',
  components: {
    ShInput,
    ShButton,
  },
  data() {
    return {
      isValid: false,
      currType: 'head',  // this.$route.params.type,
      fileList: [],
      uploadProgress: 0,
      isShowProgress: false,
      currFileUrl: null,
      currEidtFileUrl: null,
      uploadTip: '',
      baseUrl: config.baseUrl,
      uploadHeaders: {
        'account-id': getAccountId(),
        'Authorization': getToken()
      },
      loadingData: false,
      queryParams: {
        skip: 0,
        limit: 10,
        chicken_custom_id: '',
        start_time: '',
        end_time: ''
      },
      list: [],
      count: 0,
      currPage: 1,
      chickenIdList: [],
      currFilterChickenId: '全部',
      currMenuItem: {},
      currMenuOne: null,
      currMenuTwo: null,
      currImageRegionDialogType: 0,   // 0：新增， 1：编辑
      imageRegionDialogWidth: 500,
      currImageRegion: [],
      postParams: {
        chicken_custom_id: null,
        source_image: null,
      },
      editPostParams: {
        chicken_custom_id: null,
      },
      imageFrameStart: false,
      detailDialogVisible: false,
      instrDialogVisible: false,
      imageRegionDialogVisible: false,
      editDialogVisible: false,
      menuList: config.menuList,
      currViewDetailData: {},
      editIsDisabled: false,
      dateTimeFilter: '',
    }
  },
  methods: {
    handleExport() {
      getChickenListFun(this.currType, {...this.queryParams, limit: 100000}).then(res=>{
        let currTime = moment().valueOf()
        let list = res.data.data
        import('@/libs/Export2Excel').then(excel => {
          const tHeader = ['ID', '图片路径', '鸡冠周长(cm)', '鸡冠面积(cm²)', '鸡冠颜色等级', '鸡冠平均RGB', '鸡冠标准差RGB', '鸡冠高度(cm)', '鸡垂周长(cm)', '鸡垂面积(cm²)', '鸡垂颜色等级', '鸡垂平均RGB', '鸡垂标准差RGB', '鸡眼宽度(cm)', '鸡眼高度(cm)', '鸡喙面积(cm²)', '状态', '日期']
          const filterVal = ['id', 'detect_image', 'comb_perimeter', 'comb_area', 'comb_color_level', 'comb_rgb_mean', 'comb_rgb_std', 'comb_height', 'wattle_perimeter', 'wattle_area', 'wattle_color_level', 'wattle_rgb_mean', 'wattle_rgb_std', 'eye_width', 'eye_height', 'beak_area' , 'status', 'created_at']
          const data = list.map(v => filterVal.map(j => {
            if (j === 'created_at') {
              return moment(v[j]).format("YYYY-MM-DD HH:mm:ss")
            } else if (j === 'status') {
                if(v[j] === 1) {
                  return '等待中'
                } else if(v[j] === 2) {
                  return '处理中'
                } else if(v[j] === 3) {
                  return '处理成功'
                } else {
                  return '未能识别'
                }
            } else if(j === 'source_size') {
              return Math.round(v[j]/1024)
            } else {
              return v[j]
            }
          }))
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: `${this.currType}-table-list-${currTime}`
          })
        })
      }).catch(() => {
        Message.error('请求数据错误')
      })
    },
    submitUpload() {
      if(this.fileList.length <= 0 || this.fileList[0].name === '') {
        // this.uploadTip = '请选择图片'
        Message.error('请选择图片')
        return false
      }
      this.isShowProgress = true
      // this.uploadTip = '上传中...'
      Message.info('上传中...')
      this.$refs.upload.submit();
    },
    handleExceed(files, fileList) {
      this.$set(fileList[0], 'raw', files[0]);
      this.$set(fileList[0], 'name', files[0].name);
      this.$refs['upload'].clearFiles();//清除文件
      this.$refs['upload'].handleStart(files[0]);//选择文件后的赋值方法
    },
    handleChange (file, fileList) {
      let _this = this
      console.log('filesss', file)
      console.log('fileList2', fileList)
      console.log('url', URL.createObjectURL(file.raw))
      let reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = () => {
        let img = new Image()
        img.src = reader.result
        img.onload = () => {
          console.log('img', img.width)
          console.log('img', img.height)
          _this.postParams.image_ratio = img.width / _this.imageRegionDialogWidth
        }
      }

      if(file.size > 15 * 1024 * 1024) {
        // this.uploadTip = '所选图片过大'
        Message.error('所选图片过大')
        return false
      }
      if(file.status === 'success' || file.status === 'fail') {
        this.$refs['upload'].clearFiles()
      } else {
        // this.uploadTip = ''
        this.fileList = []
        this.fileList.push(file)
        this.currFileUrl = URL.createObjectURL(file.raw)
      }
      console.log('this.filelist', this.fileList)
    },
    setTimeoutTip() {
      setTimeout(() => {
        this.fileList = []
        this.uploadTip = ''
      }, 2000)
    },
    setShowProgress() {
      setTimeout(() => {
        this.isShowProgress = false
      }, 200)
    },
    handleProgress (event, file, fileList) {
      this.uploadProgress = parseFloat(event.percent).toFixed(2)
    },
    handleSuccess (res, file, fileList) {
      if(res.result_code === 0) {
        // this.uploadTip = '上传成功，正在进行识别...'
        Message.info('上传成功，正在进行识别...')
        // this.$refs['upload'].clearFiles()
        this.postParams.source_image = res.data.key
        submitChickenFun(this.currType, this.postParams).then(res=>{
          Message.info('识别成功，请稍后点击刷新按钮查看结果...')
          this.fileList = []
          for(let k in this.postParams) {
            this.postParams[k] = null
          }
        }).catch(() => {
          Message.error('识别失败，请重试...')
          this.fileList = []
          for(let k in this.postParams) {
            this.postParams[k] = null
          }
        })
      } else {
        // this.uploadTip = res.err_detail
        Message.error(res.err_detail)
        // this.setTimeoutTip()
        this.fileList = []
      }
      this.setShowProgress()
    },
    handleError (err, file, fileList) {
      // this.uploadTip = '上传失败'
      Message.error('上传失败')
      this.$refs['upload'].clearFiles()
      this.fileList = []
      this.setShowProgress()
    },
    chickenList (params) {
      this.loadingData = true
      getChickenListFun(this.currType, params).then(res=>{
        this.list = res.data.data
        this.list.forEach((item) => {
          item.date = moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
          // item.source_size2 = Math.round(item.source_size/1024)
          if(item.status === 1) {
            item.statusTxt = '等待中'
          } else if(item.status === 2) {
            item.statusTxt = '处理中'
          } else if(item.status === 3) {
            item.statusTxt = '处理成功'
          } else {
            item.statusTxt = '未能识别'
          }
        })
        this.loadingData = false
      }).catch(() => {
        this.loadingData = false
      })
    },  
    getChickenCount (params) {
      getChickenCountFun(this.currType, params).then(res => {
        this.count = res.data.data.count
      })
    },
    getChickenIdList () {
      getChickenIdListFun({
        skip: 0,
        limit: 1000
      }).then(res => {
        this.chickenIdList = res.data.data
      })
    },
    search () {
      this.queryParams.skip = 0
      this.currPage = 1
      this.refresh()
    },
    refresh () {
      this.getChickenCount(this.queryParams)
      this.chickenList(this.queryParams)
      this.getChickenIdList()
    },
    dateChange (e) {
      console.log(e)
      // console.log(this.dateTimeFilter)
      if(e) {
        this.queryParams.start_time = moment(e[0]).valueOf()
        this.queryParams.end_time = moment(e[1]).valueOf()
      } else {
        this.queryParams.start_time = ''
        this.queryParams.end_time = ''
      }
    },
    currPageChange (currPage) {
      this.queryParams.skip = (currPage - 1) * this.queryParams.limit
      this.chickenList(this.queryParams)
    },
    toPath (index, type) {
      this.currMenuNav= index
      this.$router.push({name: 'poultry', params: {type: type}})
    },
    handleChickenIdFilter (command) {
      this.currFilterChickenId = command
      if(command !== '全部') {
        this.queryParams.chicken_custom_id = command
      } else {
        this.queryParams.chicken_custom_id = ''
      }
      this.getChickenCount(this.queryParams)
      this.chickenList(this.queryParams)
    },
    viewDetail (obj) {
      this.currImageRegionDialogType = 1
      this.detailDialogVisible = true
      this.editPostParams.chicken_custom_id = obj.chicken_custom_id
      this.currViewDetailData = obj
      for(let key in this.editPostParams) {
        if(key === 'region') {
          this.editPostParams[key] = obj[key]
        }
      }
    },
    updateChickenById (id) {
      updateChickenFun(this.currType, id, this.editPostParams).then(res=>{
        this.getChickenCount(this.queryParams)
        this.chickenList(this.queryParams)
        this.getChickenIdList()
        // this.currViewDetailData.chicken_custom_id = this.editPostParams.chicken_custom_id
        for(let k in this.editPostParams) {
          this.currViewDetailData[k] = this.editPostParams[k]
        }
        Message.success('修改成功')
      }).catch(() => {
        Message.error('修改失败')
      })
    },
  },
  watch: {
  },
  created(){
    this.currMenuItem = {
      code: 'head',
      name: '鸡头识别',
      pathName: 'poultryIndex',
      fun: [],
      des: ['在有色卡的背景下，获取鸡头外轮廓，并精准计算鸡头各部位特征', '如鸡冠和鸡垂的周长、面积、高度、颜色等级、平均RGB，方差RGB；鸡眼的宽高；鸡喙的面积等'],
      showIdInput: true,
      detailList: [{
        key: 'comb_perimeter',
        title: '鸡冠周长',
        unit: 'cm'
      },{
        key: 'comb_area',
        title: '鸡冠面积',
        unit: 'cm²'
      },{
        key: 'comb_color_level',
        title: '鸡冠颜色等级',
        unit: ''
      },{
        key: 'comb_rgb_mean',
        title: '鸡冠平均RGB',
        unit: ''
      },{
        key: 'comb_rgb_std',
        title: '鸡冠标准差RGB',
        unit: ''
      },{
        key: 'comb_height',
        title: '鸡冠高度',
        unit: 'cm'
      },{
        key: 'wattle_perimeter',
        title: '鸡垂周长',
        unit: 'cm'
      },{
        key: 'wattle_area',
        title: '鸡垂面积',
        unit: 'cm²'
      },{
        key: 'wattle_color_level',
        title: '鸡垂颜色等级',
        unit: ''
      },{
        key: 'wattle_rgb_mean',
        title: '鸡垂平均RGB',
        unit: ''
      },{
        key: 'wattle_rgb_std',
        title: '鸡垂标准差RGB',
        unit: ''
      },{
        key: 'eye_width',
        title: '鸡眼宽度',
        unit: 'cm'
      },{
        key: 'eye_height',
        title: '鸡眼高度',
        unit: 'cm'
      },{
        key: 'beak_area',
        title: '鸡喙面积',
        unit: 'cm²'
      }],
      instr: [
        {
          img: '/images/fowl/case_success.jpeg',
          des: '1. 羽毛分散开，彼此不要覆盖 <br />2. 标尺的数字要连续，要铺平'
        },
        {
          img: '/images/fowl/case_success.jpeg',
          des: '1. 羽毛分散开，彼此不要覆盖 <br />2. 标尺的数字要连续，要铺平'
        },
        {
          img: '/images/fowl/case_success.jpeg',
          des: '1. 羽毛分散开，彼此不要覆盖 <br />2. 标尺的数字要连续，要铺平'
        }
      ]
    },
    this.getChickenCount(this.queryParams)
    this.chickenList(this.queryParams)
    this.getChickenIdList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'poultry.scss'
</style>
